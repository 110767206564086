/* colors */
.st-text-primary {
    color: var(--primaryColor) !important;
}
.st-text-secondary {
    color: var(--secondaryColor) !important;
}

.st-text-gray {
    color: gray !important;
}
.st-text-light {
    color: var(--textLightColor) !important;
}
.st-text-dark {
    color: var(--textDarkColor) !important;
}

/* bg colors */
.st-bg-primary {
    background-color: var(--primaryColor) !important;
}
.st-bg-secondary {
    background-color: var(--secondaryColor) !important;
}
.st-bg-slate {
    background-color: var(--slateBgColor) !important;
}
.st-bg-outer-space {
    background-color: var(--bgOuterSpace) !important;
}
.st-bg-outer-space-light {
    background-color: var(--bgOuterSpaceLight) !important;
}
