.st-round-icon-btn {
    height: 40px;
    width: 40px;
    transition: var(--defaultTransitionTiming);
    background-color: var(--textLightColor);
    color: #fff;
}
.st-round-icon-btn {
}
/* primary btn */
.st-round-icon-btn.st-btn-primary {
    background-color: var(--primaryColor);
}
.st-round-icon-btn.st-btn-primary:hover,
.st-round-icon-btn.st-btn-primary:active {
    background-color: var(--primaryDarkColor);
}
/* secondary btn */
.st-round-icon-btn.st-btn-secondary {
    background-color: var(--secondaryColor);
}
.st-round-icon-btn.st-btn-secondary:hover,
.st-round-icon-btn.st-btn-secondary:active {
    background-color: var(--primaryColor);
}

/* btn main */
.st-btn {
    background-color: var(--textLightColor);
    color: #fff;
    transition: var(--defaultTransitionTiming);
    box-shadow: none;
    line-height: normal;
    font-weight: normal;
    font-size: 16px;
    border: none;
    /* padding: 10px 17px 11px; */
    padding: 11px 17px 10px;
    border-radius: 0;
    min-width: 120px;
}
.st-btn:not(.st-btn-rounded-0) {
    border-radius: 40px;
}
.st-btn:hover,
.st-btn:active {
    background-color: var(--textDarkColor);
    color: #fff;
}
.st-btn:focus {
    box-shadow: none;
}

/* PRIMARY BTN */
.st-btn.st-btn-primary {
    background-color: var(--primaryColor);
}
.st-btn.st-btn-primary:hover,
.st-btn.st-btn-primary:active {
    background-color: var(--primaryDarkColor);
}

/* SECONDARY BTN */
.st-btn.st-btn-secondary {
    background-color: var(--secondaryColor);
}
.st-btn.st-btn-secondary:hover,
.st-btn.st-btn-secondary:active {
    background-color: var(--primaryColor);
}

/* WHITE BTN */
.st-btn.st-btn-white {
    background-color: #fff;
    color: #333;
}
.st-btn.st-btn-white:hover,
.st-btn.st-btn-white:active {
    background-color: #333;
    color: #fff;
}

/* OUTLINED SECONDARY BTN */
.st-btn.st-btn-outlined-secondary {
    background-color: transparent;
    border: 1px solid var(--secondaryColor);
    color: var(--secondaryColor);
}
.st-btn.st-btn-outlined-secondary:hover,
.st-btn.st-btn-outlined-secondary:active {
    background-color: var(--secondaryColor);
    color: #fff;
}

/* OUTLINED PRIMARY BTN */
.st-btn.st-btn-outlined-primary {
    background-color: transparent;
    border: 1px solid var(--primaryColor);
    color: var(--primaryColor);
}
.st-btn.st-btn-outlined-primary:hover,
.st-btn.st-btn-outlined-primary:active {
    background-color: var(--primaryColor);
    color: #fff;
}

/* SIZES */
.st-btn.st-btn-xs {
    padding: 8px 14px 8px;
    min-width: auto;
}
.st-btn.st-btn-lg {
    padding: 12px 17px 13px;
    min-width: 170px;
}
.st-btn.no-min-width {
    min-width: auto;
}