/* FONT STYLES CLASSES */
.text-decoration-line-through {
    text-decoration: line-through !important;
}
.text-decoration-underline {
    text-decoration: underline !important;
}

/* FONT WEIGHT CLASSES */
.font-weight-100 {
    font-weight: 100 !important;
}

.font-weight-200 {
    font-weight: 200 !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-800 {
    font-weight: 800 !important;
}

.font-weight-900 {
    font-weight: 900 !important;
}

/* SIZES */
.font-size-10 {
    font-size: 10px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-19 {
    font-size: 19px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

/* TEXT COLOR CLASSES */
a.st-text-light,
a.st-text-dark,
a.st-text-secondary,
a.st-text-primary {
    transition: var(--defaultTransitionTiming);
}

a.st-text-light:hover {
    color: var(--textDarkColor) !important;
}

a.st-text-primary:hover {
    color: var(--primaryDarkColor) !important;
}

a.st-text-secondary:hover {
    color: var(--primaryColor) !important;
}
