/* HOMEPAGE SLIDER */
#home-slider {
}
.home-slider {
}
.home-slider .slider-item-inner {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
}
@media screen and (min-width: 768px) {
    .home-slider .slider-item-inner {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}
@media screen and (min-width: 992px) {
    .home-slider .slider-item-inner {
        padding-top: 170px;
        padding-bottom: 170px;
    }
}

/* WHAT WE OFFER */
#what-we-offer {
}
.what-we-offer {
}

.what-we-offer .slider-item-inner {
}

.what-we-offer .slider-item .img-sec {
    height: 170px;
    width: 170px;
    background-color: var(--lightBgColor);
    border: 5px solid #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 1200px) {
    .what-we-offer .slider-item .img-sec {
        height: 200px;
        width: 200px;
    }
}

.what-we-offer .slick-arrow {
    background-color: var(--primaryColor);
    color: #fff;
    height: 40px;
    width: 40px;
    z-index: 1;
    top: 35%;
    transition: var(--defaultTransitionTiming);
}
.what-we-offer .slick-arrow::before {
    content: none;
}
.what-we-offer .slick-arrow:hover,
.what-we-offer .slick-arrow:focus {
    background-color: var(--secondaryColor);
    color: var(--primaryColor);
}
.what-we-offer .slick-prev {
    left: 0;
}
.what-we-offer .slick-next {
    right: 0;
}
@media screen and (min-width: 992px) {
    .what-we-offer .slick-prev {
        left: -15px;
    }
    .what-we-offer .slick-next {
        right: -15px;
    }
}

/* STORY OF SUCESS */
#story-of-sucess {
    background-repeat: no-repeat;
    background-size: cover;
}
.story-of-sucess {
}
.story-of-sucess .slick-dots {
    margin-top: 20px;
    position: static;
}
.story-of-sucess .slick-dots li {
    margin: 0;
}
.story-of-sucess .slick-dots li button::before {
    font-size: 8px;
    color: var(--textLightColor);
}
.story-of-sucess .slick-dots li.slick-active button::before {
    font-size: 12px;
    color: var(--secondaryColor);
}

/* home daily tips */
#home-daily-tips {
}
.home-daily-tips {
}
.home-daily-tips .rt-sec > .inner {
    /* margin-bottom: -3px; */
}

/* health meter main */
#home-health-meter {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
}
.home-health-meter {
}
