/* HEALTH METER STYLES */
#health-meter-main {
}
.health-meter-main-inner {
}

#health-meter-main .form-container > .st-form:first-child + .st-form::before {
    content: "";
    width: 1px;
    height: 25px;
    background-color: var(--borderDefaultColor);
    position: absolute;
    left: 0;
    top: 10px;
}

@media screen and (min-width: 768px) {
    .heath-meter-final-result {
        width: 90%;
    }
}
.heath-meter-final-result .item:nth-child(even) {
    flex-direction: row-reverse;
}

@media screen and (min-width: 768px) {
    .heath-meter-final-result .item:nth-child(even) {
        flex-direction: row-reverse;
    }
}

#health-meter-main .loader,
.modal-loader {
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}
