@import "components/_reset.css";
@import "components/_fonts.css";
@import "components/_variables.css";
@import "components/_forms.css";
@import "components/_common.css";
@import "components/_colors.css";
@import "components/_buttons.css";
@import "components/_headings.css";
@import "components/_text.css";

/* google fonts */
/* font-family: 'Lato', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

/* MAIN STYLES STARTS */
body {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: 400;
    /* line-height: normal; */
}

/* APP ROOT */
.app-root {
}

/* P SEARCH */
#P-search {
}
.P-search {
}

/* NAVBAR-TOGGLER */
.navbar-toggler {
    /* background-color: var(--primaryColor); */
    /* padding: 2px 5px; */
    /* border-radius: 3px; */
    padding: 0;
    border: none;
    font-size: 20px;
}

.navbar-toggler-icon {
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important; */
}

/* .navbar-toggler.not-visible {
    display: none !important;
} */