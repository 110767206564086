/* RECIPES SEARCH */
#recipes-search {
}
.recipes-search {
}

.recipes-search-main {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
}
@media screen and (min-width: 768px) {
    .recipes-search-main {
        border-radius: 40px;
        padding: 0;
    }
}

.recipes-search-main input,
.recipes-search-main select {
    border: none !important;
    background-color: transparent !important;
}
@media screen and (min-width: 768px) {
    .recipes-search-main .frac.select .st-form::after,
    .recipes-search-main .frac.switch .st-form::after {
        content: "";
        width: 1px;
        height: 25px;
        background-color: var(--colorGray1);
        position: absolute;
        right: 0;
        top: 10px;
    }
}

.recipes-search-main .frac.switch > .inner {
    height: 42px;
}

.recipes-search-main {
    width: 100%;
}

@media screen and (min-width: 768px) {
    /* .recipes-search-main .frac.select {
        width: 170px;
    }
    .recipes-search-main .frac.switch {
        width: 100px;
    }
    .recipes-search-main .frac.input {
        width: 200px;
    } */
}
@media screen and (min-width: 992px) {
    .recipes-search-main {
        width: 400px;
    }
    /* .recipes-search-main .frac.select {
        width: 250px;
    }
    .recipes-search-main .frac.switch {
        width: 130px;
    }
    .recipes-search-main .frac.input {
        width: 250px;
    } */
}

/* @media screen and (min-width: 768px) {
    .recipes-search-main .btns .st-btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
@media screen and (min-width: 992px) {
    .recipes-search-main .btns .st-btn {
        min-width: 150px;
    }
} */

/* RECIPES LIST */
#recipes-list {
}
.recipes-list {
}
.recipes-list .recipe-item .img-sec {
    /* height: 160px; */
    background-color: var(--lightBgColor);
}
@media screen and (max-width: 575px) {
    .recipes-list .recipe-item .img-sec img {
        width: 100%;
    }
}
@media screen and (min-width: 576px) {
    .recipes-list .recipe-item .img-sec {
        height: 160px;
    }
}

.recipes-list .recipe-item > .inner {
    border-radius: 20px;
    color: var(--textDarkColor);
    transition: var(--defaultTransitionTiming);
}
.recipes-list .recipe-item > .inner:hover {
    border-radius: 20px;
    color: var(--primaryColor);
}

/* RECIPE DETAILS SECTION */
#recipe-details {
}
.recipe-details {
}

.recipe-details-img {
}
.recipe-details-img .caption {
    bottom: 15px;
}

/* RECIPE-DETAILS */
#recipe-details {
}
.recipe-details .img-sec img {
    width: 100%;
}
.recipe-details .img-sec .date {
    left: 20px;
    bottom: -8px;
}

/* RECIPES CATS */
.recipes-cats {
}
.recipes-cats .list-container {
}
.recipes-cats .list-container .link {
    padding: 9px 15px 7px;
    display: block;
    border: 1px solid rgb(235, 235, 235);
    font-size: 14px;
    border-radius: 5px;
    color: #333;
    transition: 0.2s;
    background-color: #fff;
}
.recipes-cats .list-container .link:hover {
    background-color: var(--primaryColor);
    color: #fff;
}
