/* font lato */
@font-face {
    font-family: "lato-thin";
    src: url("../../fonts/lato/Lato-Thin.ttf") format("truetype");
}
@font-face {
    font-family: "lato-light";
    src: url("../../fonts/lato/Lato-Light.ttf") format("truetype");
}
@font-face {
    font-family: "lato-regular";
    src: url("../../fonts/lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "lato-medium";
    src: url("../../fonts/lato/Lato-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "lato-semibold";
    src: url("../../fonts/lato/Lato-Semibold.ttf") format("truetype");
}
@font-face {
    font-family: "lato-bold";
    src: url("../../fonts/lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "lato-black";
    src: url("../../fonts/lato/Lato-Black.ttf") format("truetype");
}

/* font gotham */
@font-face {
    font-family: "gotham-thin";
    src: url("../../fonts/gotham/Gotham-Thin.otf") format("opentype");
}
@font-face {
    font-family: "gotham-light";
    src: url("../../fonts/gotham/Gotham-Light.otf") format("opentype");
}
@font-face {
    font-family: "gotham-medium";
    src: url("../../fonts/gotham/GothamMedium.ttf") format("truetype");
}
@font-face {
    font-family: "gotham-bold";
    src: url("../../fonts/gotham/GothamBold.ttf") format("truetype");
}
@font-face {
    font-family: "gotham-black";
    src: url("../../fonts/gotham/Gotham-Black.otf") format("opentype");
}

/* LATO FONT FAMILY */
.font-family-primary-thin {
    font-family: "lato-thin", sans-serif;
}
.font-family-primary-light {
    font-family: "lato-light", sans-serif;
}
.font-family-primary-regular {
    font-family: "lato-regular", sans-serif;
}
.font-family-primary-medium {
    font-family: "lato-medium", sans-serif;
}
.font-family-primary-medium {
    font-family: "lato-medium", sans-serif;
}
.font-family-primary-semibold {
    font-family: "lato-semibold", sans-serif;
}
.font-family-primary-bold {
    font-family: "lato-bold", sans-serif;
}
.font-family-primary-black {
    font-family: "lato-black", sans-serif;
}

/* GOTHAM FONT FAMILY */
.font-family-secondary-thin {
    font-family: "gotham-thin", sans-serif;
}
.font-family-secondary-light {
    font-family: "gotham-light", sans-serif;
}
.font-family-secondary-medium {
    font-family: "gotham-medium", sans-serif;
}
.font-family-secondary-bold {
    font-family: "gotham-bold", sans-serif;
}
.font-family-secondary-black {
    font-family: "gotham-black", sans-serif;
}