/* OUR BLOGS */
#our-blogs {
}
.our-blogs {
}
.our-blogs .st-heading-wrapper .st-desc {
}
@media screen and (min-width: 992px) {
    .our-blogs .st-heading-wrapper .st-desc {
        max-width: 50%;
        margin: 0 auto;
    }
}

.blog-item {
}
.blog-item .blog-item-inner {
    padding: 14px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.blog-item .blog-item-inner .img-sec {
    height: 180px;
}
.blog-item .blog-item-inner .img-sec img {
    max-height: 100%;
    width: auto;
}

.blog-date-caption {
    bottom: 10px;
    left: 10px;
    z-index: 1;
}
.blog-date-caption .caption-inner {
    background: rgba(var(--secondaryColorRgbValue), 0.7);
    padding: 8px 15px;
}

/* blog details */
#blog-details {
}
.blog-details {
}

.blog-details .blog-date-caption {
    bottom: initial;
    top: 25px;
    left: 25px;
}

.blog-details-sec-two {
    /* line-height: 28px; */
}

/* categories list item */
.categories-list-item {
}
.categories-list-item-inner p {
    padding-left: 20px;
}
.categories-list-item-inner p::after {
    content: "";
    position: absolute;
    top: 11px;
    left: 0;
    height: 2px;
    width: 8px;
    background-color: #333;
}

.categories-list-item.item-primary .categories-list-item-inner p::after {
    background-color: var(--primaryColor);
}
.categories-list-item.item-secondary .categories-list-item-inner p::after {
    background-color: var(--secondaryColor);
}
