/* success stories sec1 css */
#st-full-page {
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
  background-position: 0 0;
}
.st-full-page {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 992px) {
  .st-full-page {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

.st-full-page .heading-wrapper .head {
  font-size: 45px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 10px;
  text-shadow: 2px 3px #6c6c6c;
}
.st-full-page .heading-wrapper .desc {
  font-weight: 800;
}
@media screen and (min-width: 768px) {
  .st-full-page .heading-wrapper .head {
    font-size: 70px;
  }
  .st-full-page .heading-wrapper .desc {
    font-size: 25px;
  }
}
@media screen and (min-width: 992px) {
  .st-full-page .heading-wrapper .head {
    font-size: 100px;
  }
}
.st-full-page .cotent-wrapper {
  margin-top: 40px;
}
@media screen and (min-width: 992px) {
  .st-full-page .cotent-wrapper {
    margin-top: 100px;
  }
}
.st-full-page .cotent-wrapper .sec-item > .inner {
  padding: 50px 20px;
  height: 100%;
}
.st-full-page .cotent-wrapper .sec-item .img-sec {
  height: 100px;
  width: 100px;
  padding: 20px;
}
.st-full-page .cotent-wrapper .sec-item .text-sec {
  margin-top: 20px;
}
.st-full-page .cotent-wrapper .sec-item .text-sec .head {
  font-size: 25px;
  font-weight: 800;
}
.st-full-page .cotent-wrapper .sec-item .text-sec .desc {
  margin-top: 10px;
}
@media screen and (min-width: 992px) {
  .st-full-page .cotent-wrapper .sec-item .img-sec {
    height: 130px;
    width: 130px;
    padding: 5px;
  }
  .st-full-page .cotent-wrapper .sec-item .text-sec {
    margin-top: 40px;
  }
  .st-full-page .cotent-wrapper .sec-item .text-sec .head {
    font-size: 30px;
  }
  .st-full-page .cotent-wrapper .sec-item .text-sec .desc {
    font-size: 17px;
  }
}

@media screen and (max-width: 575px) {
  .st-full-page .cotent-wrapper .sec-item:nth-child(even) > .inner {
    background-color: #dedc10;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(odd) > .inner {
    background-color: #149159;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(even) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(even) .text-sec .desc {
    color: #333;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(odd) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(odd) .text-sec .desc {
    color: #fff;
  }
}
@media screen and (min-width: 768px) {
  .st-full-page .cotent-wrapper .sec-item:nth-child(even) > .inner {
    background-color: #dedc10;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(odd) > .inner {
    background-color: #149159;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(even) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(even) .text-sec .desc {
    color: #333;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(odd) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(odd) .text-sec .desc {
    color: #fff;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .st-full-page .cotent-wrapper .sec-item > .inner {
    background-color: #149159;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(2) > .inner,
  .st-full-page .cotent-wrapper .sec-item:nth-child(3) > .inner,
  .st-full-page .cotent-wrapper .sec-item:nth-child(6) > .inner {
    background-color: #dedc10;
  }
  .st-full-page .cotent-wrapper .sec-item .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item .text-sec .desc {
    color: #fff;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(2) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(3) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(6) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(2) .text-sec .desc,
  .st-full-page .cotent-wrapper .sec-item:nth-child(3) .text-sec .desc,
  .st-full-page .cotent-wrapper .sec-item:nth-child(6) .text-sec .desc {
    color: #333;
  }
}
