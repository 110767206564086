:root {
    --primaryColor: #149159;
    --primaryColorRgbValue: 20, 145, 89;
    --secondaryColor: #a7d433;
    --secondaryColorRgbValue: 167, 212, 51;

    --primaryDarkColor: #127b4c;
    --primaryLightestColor: #f8fdf6;
    --primaryLightBorderColor: #f2f2f0;

    /* --textLightColor: #333; */
    --textLightColor: #444;
    --textDarkColor: #000;

    --lightBgColor: #efefef;

    --bgOuterSpace: #252d32;
    --bgOuterSpaceLight: #2b343b;

    /* --slateBgColor: #f3f3f3; */
    --slateBgColor: #f7f7f7;

    --borderGrayColor: #c6c6c6;
    --borderOuterSpaceColor: #3c4953;
    --borderDefaultColor: #ced4da;

    --defaultTransitionTiming: 0.2s;

    --appDefaultBorderRadius: 6px;

    --colorSuccess: #55aa45;
    --colorGray1: #e2e2e3;



    --media-breakpoint-sm-min: 576px;
    --media-breakpoint-sm-max: 575px;

    --media-breakpoint-md-min: 768px;
    --media-breakpoint-md-max: 767px;

    --media-breakpoint-lg-min: 992px;
    --media-breakpoint-lg-max: 991px;

    --media-breakpoint-xl-min: 1200px;
    --media-breakpoint-xl-max: 1199px;
}
