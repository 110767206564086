/* MY ACCOUNT CSS */
.account-menu {
}

.my-account {
  min-height: 500px;
}

@media screen and (min-width: 768px) {
  .ma-leftbar {
    width: 285px;
  }

  .ma-content {
    margin-left: 285px;
    width: calc(100% - 285px);
  }
}

.ma-leftbar-user-details {
  box-shadow: 0 1px 2px #c3c3c3;
}

/* menu */
.ma-leftbar-menu {
}
.ma-leftbar-menu .menu-item {
}
.ma-leftbar-menu .menu-link {
  color: var(--textLightColor);
  transition: var(--defaultTransitionTiming);
}
.ma-leftbar-menu .menu-item.active > .menu-link,
.ma-leftbar-menu .menu-link.active,
.ma-leftbar-menu .menu-link:hover {
  background-color: var(--primaryColor);
  color: #fff;
}
.ma-leftbar-menu .dropdown-menu-item .menu-link {
  background: #ececec;
}
.ma-leftbar-menu .dropdown-menu-item.active > .menu-link,
.ma-leftbar-menu .dropdown-menu-item > .menu-link.active,
.ma-leftbar-menu .dropdown-menu-item > .menu-link:hover {
  background: #c3c3c3;
  color: var(--textLightColor);
}

/* MA DASHBOARD CONTENT */
.ma-content.dashboard-content {
}
.ma-content.dashboard-content .frac > .inner {
  transition: var(--defaultTransitionTiming);
}
.ma-content.dashboard-content .frac:hover > .inner {
  border-color: #bebebe !important;
}

/* MA LOGIN INFORMATION CONTENT */
.ma-content.login-information-content {
}

/* diet plan */
.diet-plan-container {
}
.diet-plan-container ul {
  list-style: disc;
  padding-left: 20px;
}

/* aarogyam list container */
.aarogyam-list-container {
  margin: 0 -10px;
}
.aarogyam-list-container .pdf-item {
  padding: 0 10px 20px;
  min-width: 150px;
}
.aarogyam-list-container .pdf-item > .inner {
  background: #efefef;
  padding: 15px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  /* cursor: pointer; */
  /* transition: 0.2s; */
}
/* .aarogyam-list-container .pdf-item > .inner:hover {
  background: #dfdfdf;
} */

.aarogyam-list-container .pdf-item .icon {
  margin-bottom: 10px;
}
.aarogyam-list-container .pdf-item .text {
  line-height: normal;
}

.aarogyam-list-container .pdf-item .text p {
  font-size: 15px;
  font-weight: 600;
}
.aarogyam-list-container .pdf-item .text a {
  font-size: 13px;
  font-weight: 400;
}
