@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    /* vertical-align: baseline; */
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* font lato */
@font-face {
    font-family: "lato-thin";
    src: url(/static/media/Lato-Thin.6734a19b.ttf) format("truetype");
}
@font-face {
    font-family: "lato-light";
    src: url(/static/media/Lato-Light.d9264754.ttf) format("truetype");
}
@font-face {
    font-family: "lato-regular";
    src: url(/static/media/Lato-Regular.80c07943.ttf) format("truetype");
}
@font-face {
    font-family: "lato-medium";
    src: url(/static/media/Lato-Medium.dffae175.ttf) format("truetype");
}
@font-face {
    font-family: "lato-semibold";
    src: url(/static/media/Lato-Semibold.d585c7df.ttf) format("truetype");
}
@font-face {
    font-family: "lato-bold";
    src: url(/static/media/Lato-Bold.bb154e8d.ttf) format("truetype");
}
@font-face {
    font-family: "lato-black";
    src: url(/static/media/Lato-Black.f03c3fca.ttf) format("truetype");
}

/* font gotham */
@font-face {
    font-family: "gotham-thin";
    src: url(/static/media/Gotham-Thin.6447770f.otf) format("opentype");
}
@font-face {
    font-family: "gotham-light";
    src: url(/static/media/Gotham-Light.59aa4004.otf) format("opentype");
}
@font-face {
    font-family: "gotham-medium";
    src: url(/static/media/GothamMedium.0a910c0b.ttf) format("truetype");
}
@font-face {
    font-family: "gotham-bold";
    src: url(/static/media/GothamBold.108854bb.ttf) format("truetype");
}
@font-face {
    font-family: "gotham-black";
    src: url(/static/media/Gotham-Black.c9e42599.otf) format("opentype");
}

/* LATO FONT FAMILY */
.font-family-primary-thin {
    font-family: "lato-thin", sans-serif;
}
.font-family-primary-light {
    font-family: "lato-light", sans-serif;
}
.font-family-primary-regular {
    font-family: "lato-regular", sans-serif;
}
.font-family-primary-medium {
    font-family: "lato-medium", sans-serif;
}
.font-family-primary-medium {
    font-family: "lato-medium", sans-serif;
}
.font-family-primary-semibold {
    font-family: "lato-semibold", sans-serif;
}
.font-family-primary-bold {
    font-family: "lato-bold", sans-serif;
}
.font-family-primary-black {
    font-family: "lato-black", sans-serif;
}

/* GOTHAM FONT FAMILY */
.font-family-secondary-thin {
    font-family: "gotham-thin", sans-serif;
}
.font-family-secondary-light {
    font-family: "gotham-light", sans-serif;
}
.font-family-secondary-medium {
    font-family: "gotham-medium", sans-serif;
}
.font-family-secondary-bold {
    font-family: "gotham-bold", sans-serif;
}
.font-family-secondary-black {
    font-family: "gotham-black", sans-serif;
}
:root {
    --primaryColor: #149159;
    --primaryColorRgbValue: 20, 145, 89;
    --secondaryColor: #a7d433;
    --secondaryColorRgbValue: 167, 212, 51;

    --primaryDarkColor: #127b4c;
    --primaryLightestColor: #f8fdf6;
    --primaryLightBorderColor: #f2f2f0;

    /* --textLightColor: #333; */
    --textLightColor: #444;
    --textDarkColor: #000;

    --lightBgColor: #efefef;

    --bgOuterSpace: #252d32;
    --bgOuterSpaceLight: #2b343b;

    /* --slateBgColor: #f3f3f3; */
    --slateBgColor: #f7f7f7;

    --borderGrayColor: #c6c6c6;
    --borderOuterSpaceColor: #3c4953;
    --borderDefaultColor: #ced4da;

    --defaultTransitionTiming: 0.2s;

    --appDefaultBorderRadius: 6px;

    --colorSuccess: #55aa45;
    --colorGray1: #e2e2e3;



    --media-breakpoint-sm-min: 576px;
    --media-breakpoint-sm-max: 575px;

    --media-breakpoint-md-min: 768px;
    --media-breakpoint-md-max: 767px;

    --media-breakpoint-lg-min: 992px;
    --media-breakpoint-lg-max: 991px;

    --media-breakpoint-xl-min: 1200px;
    --media-breakpoint-xl-max: 1199px;
}

.st-form {
}
.st-form input:not([type="radio"]):not([type="checkbox"]),
.st-form select {
    height: 42px;
}

.st-form input:not([type="radio"]):not([type="checkbox"]),
.st-form select,
.st-form textarea {
    box-shadow: none;
    border-radius: 0;
}

.st-form input:not([type="radio"]):not([type="checkbox"]):focus,
.st-form select:focus,
.st-form textarea:focus {
    box-shadow: none;
    border-color: var(--textLightColor) !important;
}

/* CUSTOM SELECT */
.st-form:not(.not-custom-select) select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAJCAYAAAACTR1pAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4FpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA1IDc5LjE2NDU5MCwgMjAyMC8xMi8wOS0xMTo1Nzo0NCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDplZjhkNmUyZi1lYWZkLTA4NDEtOTExNC0xNWJkMWUzYjYyOWQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUFCQzAxMDI2Rjk4MTFFQjg2RDBEM0JDNUNFQTE3MjAiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUFCQzAxMDE2Rjk4MTFFQjg2RDBEM0JDNUNFQTE3MjAiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjEgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NjljYjUzY2YtZWE2MC1jYzQ0LTlmYzctNDNjZTE5YzhlNjY3IiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6ZGY3ODc2ZTAtMjg1Mi1lNDQ0LWIyOGYtMDNmMmYyMzVmOGFhIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+rECt9wAAAIFJREFUeNpiZACCWac00oAUCLummd14z4AFANUYA6lVQBwGVHOWESgQChUAgbPYNEM17QZiQSAGyZkwoRkMVgBUKIhDExwwIjl1JpI42GYgVkLT9B7qorOMSCZj06yETRPcRjyaGbBpwtCIQzOGJqwa0TRj1YQXgDQDsQsueYAAAwBR+T2QhMarZQAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: 12px;
    padding-right: 30px;
}

/* WIDTHS */
.st-form.three-in-one-line {
    width: 100%;
}
@media screen and (min-width: 768px) {
    .st-form.three-in-one-line.center {
        padding-left: 10px;
        padding-right: 10px;
    }
    .st-form.three-in-one-line {
        width: 33.3%;
    }
}

/* WITH ICON */
.st-form.with-icon-left .icon,
.st-form.with-icon-right .icon {
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
}
.st-form.with-icon-left .icon {
    left: 10px;
}
.st-form.with-icon-right .icon {
    right: 10px;
}
.st-form.with-icon-left input:not([type="radio"]):not([type="checkbox"]),
.st-form.with-icon-left select,
.st-form.with-icon-left textarea {
    padding-left: 35px;
}
.st-form.with-icon-right input:not([type="radio"]):not([type="checkbox"]),
.st-form.with-icon-right select,
.st-form.with-icon-right textarea {
    padding-right: 35px;
}

/* FORM ROUNDED */
.st-form.st-form-rounded input:not([type="radio"]):not([type="checkbox"]),
.st-form.st-form-rounded select,
.st-form.st-form-rounded textarea {
    border-radius: 40px;
}

/* FORM ROUNDED */
.st-form.st-form-has-shadow input:not([type="radio"]):not([type="checkbox"]),
.st-form.st-form-has-shadow select,
.st-form.st-form-has-shadow textarea {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border: none !important;
}

/* ERROR MESSAGES */
.st-form + .error {
    top: -12px;
}

/* HIDE ARROWS */
/* Chrome, Safari, Edge, Opera */
.st-form > .hide-number-arrows::-webkit-outer-spin-button,
.st-form > .hide-number-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.st-form > .hide-number-arrows[type="number"] {
    -moz-appearance: textfield;
}

/* error messages */
.form-error {
    position: absolute;
    bottom: -20px;}
.form-error p {
    font-size: 13px;
    color: #f74343;
}

/* BORDER CLASSES */
.st-border-gray {
    border-color: var(--borderGrayColor) !important;
}
.st-border-outer-space {
    border-color: var(--borderOuterSpaceColor) !important;
}
.st-border-primary {
    border-color: var(--primaryColor) !important;
}
.st-border-secondary {
    border-color: var(--secondaryColor) !important;
}
.st-border-default {
    border-color: var(--borderDefaultColor) !important;
}

/* SOCIAL LINKS ICONS */
.social-links-icons {
}
.social-links-icons > li {
}
.social-links-icons > li:not(:last-child) {
    margin-right: 5px;
}
.social-links-icons > li > a {
    height: 30px;
    width: 30px;
}

/* COMMON MARGINS AND PADDING */
.ST_def-mar-T,
.ST_def-mar-TB {
    margin-top: 20px;
}
@media screen and (min-width: 992px) {
    .ST_def-mar-T,
    .ST_def-mar-TB {
        margin-top: 35px;
    }
}
@media screen and (min-width: 1200px) {
    .ST_def-mar-T,
    .ST_def-mar-TB {
        margin-top: 80px;
    }
}

.ST_def-mar-B,
.ST_def-mar-TB {
    margin-bottom: 20px;
}
@media screen and (min-width: 992px) {
    .ST_def-mar-B,
    .ST_def-mar-TB {
        margin-bottom: 35px;
    }
}
@media screen and (min-width: 1200px) {
    .ST_def-mar-B,
    .ST_def-mar-TB {
        margin-bottom: 80px;
    }
}

.ST_def-pad-T,
.ST_def-pad-TB {
    padding-top: 20px;
}
@media screen and (min-width: 992px) {
    .ST_def-pad-T,
    .ST_def-pad-TB {
        padding-top: 35px;
    }
}
@media screen and (min-width: 1200px) {
    .ST_def-pad-T,
    .ST_def-pad-TB {
        padding-top: 80px;
    }
}

.ST_def-pad-B,
.ST_def-pad-TB {
    padding-bottom: 20px;
}
@media screen and (min-width: 992px) {
    .ST_def-pad-B,
    .ST_def-pad-TB {
        padding-bottom: 35px;
    }
}
@media screen and (min-width: 1200px) {
    .ST_def-pad-B,
    .ST_def-pad-TB {
        padding-bottom: 80px;
    }
}

/* IMAGE CLASSES */
.img-fluid-height {
    max-height: 100%;
}

/* UNDERLINE IT */
.underline-it {
}
.underline-it::after {
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    margin-top: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--textLightColor);
}
.underline-it.underline-secondary::after {
    background-color: var(--secondaryColor);
}
.underline-it.underline-primary::after {
    background-color: var(--primaryColor);
}

/* ST BREADCRUMBS */
.st-breadcrumbs {
}
.st-breadcrumbs > li:not(:last-child)::after {
    content: ">";
    margin: 0 5px;
    font-weight: 600;
    color: var(--textDarkColor);
}

.st-breadcrumbs > li > a:hover {
    color: var(--primaryColor) !important;
}

/* ST PROGRESS */
.st-progress {
    height: 14px;
    border-radius: 20px;
}
.st-progress.progress-success {
    background-color: var(--colorGray1);
}
.st-progress.progress-success > .progress-bar {
    background-color: var(--colorSuccess);
}
.st-progress.progress-primary > .progress-bar {
    background-color: var(--primaryColor);
}
.st-progress.progress-secondary > .progress-bar {
    background-color: var(--secondaryColor);
}

/* ST-BADGE */
.st-badge {
    height: 20px;
    width: 20px;
    background-color: #333;
    color: #fff;
}
.st-badge.st-badge-secondary {
    background-color: var(--secondaryColor);
}

/* BOX SHADOW */
.st-block-box-shadow {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

/* ST SWITCH */
.st-switch {
    cursor: pointer;
  }
  .st-switch .bar {
    width: 32px;
    height: 18px;
    background: #ddd;
    border-radius: 20px;
    position: relative;
    transition: 0.3s;
}
.st-switch .bar .round {
    height: 18px;
    width: 18px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}
.st-switch > input:checked ~ .bar {
    background: var(--colorSuccess);
}
.st-switch > input:checked ~ .bar .round {
    left: 15px;
}

/* ST-TAG */
.st-tag1 {
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAAAZCAYAAACb1MhvAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4FpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA1IDc5LjE2NDU5MCwgMjAyMC8xMi8wOS0xMTo1Nzo0NCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDplZjhkNmUyZi1lYWZkLTA4NDEtOTExNC0xNWJkMWUzYjYyOWQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjhCMUI0Q0U3MDYyMTFFQkI0NjFBRDhFRjRGRDk3QTYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjhCMUI0Q0Q3MDYyMTFFQkI0NjFBRDhFRjRGRDk3QTYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjEgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OGQzMjk5MmEtYWRmMy01MjQxLTlhYWItMTU5NGRjOTE0N2NjIiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6MmM1M2IxMzUtYzY3NS1jNzRkLTkxODMtOTFhYWZlOTY2Njg5Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+maGqtgAAASZJREFUeNrsmKFLA2EYh+/0MBgWZLJ/wb9g0TEYw7QVyw2xaJ1jLE2sYzY9XRMsghhMGgQFy+LaknaLCAbDYIjM57MIwvzey/d74GnfhXt47+77Lswn8SzIJu6+2297l0maixaC7BLi8epJY1/B0tEjWt9cOcOP5F9OscUjOtOE2WjiGdO2qGB2dvCCaJGC2YnxmmhLCmanjrdEW1YwO1W8I1pOweys4wPRVhTMThEfiVbQPiwdT1jRhNlZc1sOBbPzjNsKZmOMJY5NLwrmZ4RlYr3qK+ln6F70xHrXtsLPPW4Q60MbVz83WCPWREcjP1e4SaypDt9+znGLWJ/zFijYLwPcJdbXf4sidfrhkFBdy0JNWBAcWGNlfcLcT4cOsY7SXPQtwAAh9k1KuNvpIwAAAABJRU5ErkJggg==) no-repeat left
        center / auto 100%;
    padding-left: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 31px;
}

/* st-img-radio */
.st-img-radio {
    cursor: pointer;
}
.st-img-radio .box {
    transition: var(--defaultTransitionTiming);
    border-color: transparent !important;
    border-radius: var(--appDefaultBorderRadius);
    height: 150px;
}
.st-img-radio > input:checked + .box {
    border-color: var(--primaryColor) !important;
}
.st-img-radio .text {
}
.st-img-radio .text.active {
    color: var(--primaryColor);
}

/* ST-CHECKBOX */
.st-checkbox {
    cursor: pointer;
}
.st-checkbox .box {
    height: 20px;
    width: 20px;
    background-color: #ddd;
    color: #ddd;
    transition: var(--defaultTransitionTiming);
}

.st-checkbox .text {
    top: -2px;
}

.st-checkbox > input:checked + .box {
    background-color: var(--primaryColor) !important;
    color: #fff;
}

/* MODAL BOOTSTRAP STYLES */
.modal-content {
    border-radius: 0;
}

/* ALERT */
.alert {
    padding: 5px 15px 5px;
    border-radius: 0;
    font-size: 14px;
}

/* colors */
.st-text-primary {
    color: var(--primaryColor) !important;
}
.st-text-secondary {
    color: var(--secondaryColor) !important;
}

.st-text-gray {
    color: gray !important;
}
.st-text-light {
    color: var(--textLightColor) !important;
}
.st-text-dark {
    color: var(--textDarkColor) !important;
}

/* bg colors */
.st-bg-primary {
    background-color: var(--primaryColor) !important;
}
.st-bg-secondary {
    background-color: var(--secondaryColor) !important;
}
.st-bg-slate {
    background-color: var(--slateBgColor) !important;
}
.st-bg-outer-space {
    background-color: var(--bgOuterSpace) !important;
}
.st-bg-outer-space-light {
    background-color: var(--bgOuterSpaceLight) !important;
}

.st-round-icon-btn {
    height: 40px;
    width: 40px;
    transition: var(--defaultTransitionTiming);
    background-color: var(--textLightColor);
    color: #fff;
}
.st-round-icon-btn {
}
/* primary btn */
.st-round-icon-btn.st-btn-primary {
    background-color: var(--primaryColor);
}
.st-round-icon-btn.st-btn-primary:hover,
.st-round-icon-btn.st-btn-primary:active {
    background-color: var(--primaryDarkColor);
}
/* secondary btn */
.st-round-icon-btn.st-btn-secondary {
    background-color: var(--secondaryColor);
}
.st-round-icon-btn.st-btn-secondary:hover,
.st-round-icon-btn.st-btn-secondary:active {
    background-color: var(--primaryColor);
}

/* btn main */
.st-btn {
    background-color: var(--textLightColor);
    color: #fff;
    transition: var(--defaultTransitionTiming);
    box-shadow: none;
    line-height: normal;
    font-weight: normal;
    font-size: 16px;
    border: none;
    /* padding: 10px 17px 11px; */
    padding: 11px 17px 10px;
    border-radius: 0;
    min-width: 120px;
}
.st-btn:not(.st-btn-rounded-0) {
    border-radius: 40px;
}
.st-btn:hover,
.st-btn:active {
    background-color: var(--textDarkColor);
    color: #fff;
}
.st-btn:focus {
    box-shadow: none;
}

/* PRIMARY BTN */
.st-btn.st-btn-primary {
    background-color: var(--primaryColor);
}
.st-btn.st-btn-primary:hover,
.st-btn.st-btn-primary:active {
    background-color: var(--primaryDarkColor);
}

/* SECONDARY BTN */
.st-btn.st-btn-secondary {
    background-color: var(--secondaryColor);
}
.st-btn.st-btn-secondary:hover,
.st-btn.st-btn-secondary:active {
    background-color: var(--primaryColor);
}

/* WHITE BTN */
.st-btn.st-btn-white {
    background-color: #fff;
    color: #333;
}
.st-btn.st-btn-white:hover,
.st-btn.st-btn-white:active {
    background-color: #333;
    color: #fff;
}

/* OUTLINED SECONDARY BTN */
.st-btn.st-btn-outlined-secondary {
    background-color: transparent;
    border: 1px solid var(--secondaryColor);
    color: var(--secondaryColor);
}
.st-btn.st-btn-outlined-secondary:hover,
.st-btn.st-btn-outlined-secondary:active {
    background-color: var(--secondaryColor);
    color: #fff;
}

/* OUTLINED PRIMARY BTN */
.st-btn.st-btn-outlined-primary {
    background-color: transparent;
    border: 1px solid var(--primaryColor);
    color: var(--primaryColor);
}
.st-btn.st-btn-outlined-primary:hover,
.st-btn.st-btn-outlined-primary:active {
    background-color: var(--primaryColor);
    color: #fff;
}

/* SIZES */
.st-btn.st-btn-xs {
    padding: 8px 14px 8px;
    min-width: auto;
}
.st-btn.st-btn-lg {
    padding: 12px 17px 13px;
    min-width: 170px;
}
.st-btn.no-min-width {
    min-width: auto;
}
/* st line heading */
.st-line-heading {
    padding-left: calc(50px + 5px);
}
.st-line-heading::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translatey(-50%);
    width: 50px;
    height: 2px;
    background-color: var(--textLightColor);
}
/* line secondary */
.st-line-heading.line-secondary::after {
    background-color: var(--secondaryColor);
}
/* line primary */
.st-line-heading.line-primary::after {
    background-color: var(--primaryColor);
}

/* st heading */
.st-heading {
    font-size: 31px;
}
@media screen and (min-width: 768px) {
    .st-heading {
        font-size: 40px;
    }
}
@media screen and (min-width: 992px) {
    .st-heading {
        font-size: 50px;
    }
}

/* heading XS */
.st-heading.heading-xs {
    font-size: 24px;
}

/* heading SM */
.st-heading.heading-xs2 {
    font-size: 34px;
    line-height: 42px;
}

/* heading SM */
.st-heading.heading-sm {
    font-size: 31px;
    line-height: 35px;
}

@media screen and (min-width: 992px) {
    .st-heading.heading-sm {
        font-size: 40px;
        line-height: 40px;
    }
}

/* heading underline */
.st-heading.heading-underline {
    padding-bottom: 5px;
    border-bottom: 2px solid var(--borderGrayColor);
}
.st-heading.heading-underline::after {
    content: '';
    height: 4px;
    width: 70px;
    position: absolute;
    bottom: -3px;
    left: 0;
    background-color: var(--secondaryColor);
}
/* FONT STYLES CLASSES */
.text-decoration-line-through {
    text-decoration: line-through !important;
}
.text-decoration-underline {
    text-decoration: underline !important;
}

/* FONT WEIGHT CLASSES */
.font-weight-100 {
    font-weight: 100 !important;
}

.font-weight-200 {
    font-weight: 200 !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-800 {
    font-weight: 800 !important;
}

.font-weight-900 {
    font-weight: 900 !important;
}

/* SIZES */
.font-size-10 {
    font-size: 10px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-19 {
    font-size: 19px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

/* TEXT COLOR CLASSES */
a.st-text-light,
a.st-text-dark,
a.st-text-secondary,
a.st-text-primary {
    transition: var(--defaultTransitionTiming);
}

a.st-text-light:hover {
    color: var(--textDarkColor) !important;
}

a.st-text-primary:hover {
    color: var(--primaryDarkColor) !important;
}

a.st-text-secondary:hover {
    color: var(--primaryColor) !important;
}

/* google fonts */
/* font-family: 'Lato', sans-serif; */

/* MAIN STYLES STARTS */
body {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: 400;
    /* line-height: normal; */
}

/* APP ROOT */
.app-root {
}

/* P SEARCH */
#P-search {
}
.P-search {
}

/* NAVBAR-TOGGLER */
.navbar-toggler {
    /* background-color: var(--primaryColor); */
    /* padding: 2px 5px; */
    /* border-radius: 3px; */
    padding: 0;
    border: none;
    font-size: 20px;
}

.navbar-toggler-icon {
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important; */
}

/* .navbar-toggler.not-visible {
    display: none !important;
} */
/* ---------------- UPWARDS ---------------- */

/* UP XS (>) */
@media screen and (min-width: 0px) {
}

/* UP SM (>) */
@media screen and (min-width: 576px) {
}

/* UP MD (>) */
@media screen and (min-width: 768px) {
}

/* UP LG (>) */
@media screen and (min-width: 992px) {
}

/* UP XL (>) */
@media screen and (min-width: 1200px) {
}

/* ---------------- DOWNWARDS ---------------- */

/* DOWN XL (<) */
@media screen and (max-width: 1199px) {
}

/* DOWN LG (<) */
@media screen and (max-width: 991px) {
}

/* DOWN MD (<) */
@media screen and (max-width: 767px) {
    /* COMMON */
    .st-btn {
        font-size: 15px;
    }
    body {
        font-size: 15px;
    }

    /* HEADER */
    .header-top-sec > .lt-sec,
    .header-top-sec > .rt-sec {
        width: 100%;
    }
    .header-main-sec .navbar-brand {
        width: 105px;
    }
    #header-top-sec {
        background: #f4f4f4;
        padding: 5px 0;
    }
    .header-main-sec .menu-btns {
        width: 100%;
        border-top: 1px solid #dee2e6;
        padding-top: 10px;
        margin-top: 15px;
        padding-bottom: 5px;
        text-align: center;
    }
    .header-main-sec .navbar-brand {
        margin-right: 0;
    }
    .header-top-sec {
        border-bottom: none !important;
    }
    .header-main-sec .navbar-nav {
        margin-top: 15px;
    }

    /* SETTING ORDER FOR HEADER ITEM */
    .navbar-brand {
        order: 1;
    }
    .navbar-toggler {
        order: 0;
        margin-top: 11px;
    }
    .navbar-collapse {
        order: 5;
    }
    .ht-link-login-and-register {
        order: 2;
        margin-top: 11px;
    }
    .menu-btns {
        order: 4;
    }
    #header-nav-navbar .nav-link:hover,
    #header-nav-navbar .nav-link:active,
    #header-nav-navbar .nav-link.active,
    #header-nav-navbar .nav-item.show > .dropdown-toggle.nav-link {
        background-color: #f4f4f4 !important;
    }

    /* RECIPES SEARCH */
    .recipes-search-main > .frac > .inner {
        border: 1px solid var(--borderGrayColor);
        margin-bottom: 15px;
        border-radius: 40px;
        overflow: hidden;
    }
    .recipes-search-main .frac.switch > .inner {
        padding: 0 10px;
    }

    .recipes-search-main .frac.select {
        width: 60%;
    }
    .recipes-search-main .frac.switch {
        width: 45%;
        margin-left: auto;
    }
    .recipes-search-main .frac.input {
        width: 100%;
    }
    .recipes-search-main .btns,
    .recipes-search-main .btns .btn {
        width: 100%;
    }

    /* footer */
    .footer-copyright .inner {
        text-align: center !important;
    }

    /* my account sidevbar */
    .ma-mobile-icon {
        top: 9px;
        left: 17px;
        cursor: pointer;
    }
    .ma-mobile-icon.active {
        left: 256px;
    }

    .ma-leftbar {
        left: calc(-100% - 16px);
        transition: 0.3s;
        width: 240px;
    }
    .ma-leftbar.active {
        left: 0;
        transition: 0.3s;
    }

    .ma-content {
        margin-top: 25px;
        margin-left: 0;
        width: 100%;
        transition: 0.1s;
    }
    .ma-content.active {
        margin-left: 240px;
        width: calc(100% - 240px);
    }

    /* header collpase menu */
    .navbar-collapse {
        position: fixed;
        top: 0px;
        left: 0px;
        background: #fff;
        width: 260px;
        z-index: 2;
        height: 100% !important;
        transition: 0.4s;
        left: 0;
    }
    .collapse:not(.show) {
        display: block;
        left: calc(-100% + 10px);
    }
    #header-nav-navbar .nav-link {
        padding: 13px 20px !important;
    }
    .mobile-menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        height: 100%;
        width: 100%;
        z-index: 1;
    }
}

/* DOWN SM (<) */
@media screen and (max-width: 575px) {
}

/* ---------------- MIN TO MAX ---------------- */
@media screen and (min-width: 0px) and (max-width: 575px) {
}

@media screen and (min-width: 576px) and (max-width: 767px) {
}

@media screen and (min-width: 0px) and (max-width: 767px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    /* header */
    .header-main-sec .menu-btns {
        position: absolute;
        top: 22px;
        right: 40px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

/* MAIN STYLES */

/* RESPONSIVE CSS */
/* HOMEPAGE SLIDER */
#home-slider {
}
.home-slider {
}
.home-slider .slider-item-inner {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
}
@media screen and (min-width: 768px) {
    .home-slider .slider-item-inner {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}
@media screen and (min-width: 992px) {
    .home-slider .slider-item-inner {
        padding-top: 170px;
        padding-bottom: 170px;
    }
}

/* WHAT WE OFFER */
#what-we-offer {
}
.what-we-offer {
}

.what-we-offer .slider-item-inner {
}

.what-we-offer .slider-item .img-sec {
    height: 170px;
    width: 170px;
    background-color: var(--lightBgColor);
    border: 5px solid #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 1200px) {
    .what-we-offer .slider-item .img-sec {
        height: 200px;
        width: 200px;
    }
}

.what-we-offer .slick-arrow {
    background-color: var(--primaryColor);
    color: #fff;
    height: 40px;
    width: 40px;
    z-index: 1;
    top: 35%;
    transition: var(--defaultTransitionTiming);
}
.what-we-offer .slick-arrow::before {
    content: none;
}
.what-we-offer .slick-arrow:hover,
.what-we-offer .slick-arrow:focus {
    background-color: var(--secondaryColor);
    color: var(--primaryColor);
}
.what-we-offer .slick-prev {
    left: 0;
}
.what-we-offer .slick-next {
    right: 0;
}
@media screen and (min-width: 992px) {
    .what-we-offer .slick-prev {
        left: -15px;
    }
    .what-we-offer .slick-next {
        right: -15px;
    }
}

/* STORY OF SUCESS */
#story-of-sucess {
    background-repeat: no-repeat;
    background-size: cover;
}
.story-of-sucess {
}
.story-of-sucess .slick-dots {
    margin-top: 20px;
    position: static;
}
.story-of-sucess .slick-dots li {
    margin: 0;
}
.story-of-sucess .slick-dots li button::before {
    font-size: 8px;
    color: var(--textLightColor);
}
.story-of-sucess .slick-dots li.slick-active button::before {
    font-size: 12px;
    color: var(--secondaryColor);
}

/* home daily tips */
#home-daily-tips {
}
.home-daily-tips {
}
.home-daily-tips .rt-sec > .inner {
    /* margin-bottom: -3px; */
}

/* health meter main */
#home-health-meter {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
}
.home-health-meter {
}

/* OUR BLOGS */
#our-blogs {
}
.our-blogs {
}
.our-blogs .st-heading-wrapper .st-desc {
}
@media screen and (min-width: 992px) {
    .our-blogs .st-heading-wrapper .st-desc {
        max-width: 50%;
        margin: 0 auto;
    }
}

.blog-item {
}
.blog-item .blog-item-inner {
    padding: 14px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.blog-item .blog-item-inner .img-sec {
    height: 180px;
}
.blog-item .blog-item-inner .img-sec img {
    max-height: 100%;
    width: auto;
}

.blog-date-caption {
    bottom: 10px;
    left: 10px;
    z-index: 1;
}
.blog-date-caption .caption-inner {
    background: rgba(var(--secondaryColorRgbValue), 0.7);
    padding: 8px 15px;
}

/* blog details */
#blog-details {
}
.blog-details {
}

.blog-details .blog-date-caption {
    bottom: initial;
    top: 25px;
    left: 25px;
}

.blog-details-sec-two {
    /* line-height: 28px; */
}

/* categories list item */
.categories-list-item {
}
.categories-list-item-inner p {
    padding-left: 20px;
}
.categories-list-item-inner p::after {
    content: "";
    position: absolute;
    top: 11px;
    left: 0;
    height: 2px;
    width: 8px;
    background-color: #333;
}

.categories-list-item.item-primary .categories-list-item-inner p::after {
    background-color: var(--primaryColor);
}
.categories-list-item.item-secondary .categories-list-item-inner p::after {
    background-color: var(--secondaryColor);
}

/* HEALTH METER STYLES */
#health-meter-main {
}
.health-meter-main-inner {
}

#health-meter-main .form-container > .st-form:first-child + .st-form::before {
    content: "";
    width: 1px;
    height: 25px;
    background-color: var(--borderDefaultColor);
    position: absolute;
    left: 0;
    top: 10px;
}

@media screen and (min-width: 768px) {
    .heath-meter-final-result {
        width: 90%;
    }
}
.heath-meter-final-result .item:nth-child(even) {
    flex-direction: row-reverse;
}

@media screen and (min-width: 768px) {
    .heath-meter-final-result .item:nth-child(even) {
        flex-direction: row-reverse;
    }
}

#health-meter-main .loader,
.modal-loader {
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}

/* PAGE BANNER */
#page-banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.page-banner {
    padding-top: 30px;
    padding-bottom: 30px;
}

@media screen and (min-width: 768px) {
    .page-banner {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

@media screen and (min-width: 992px) {
    .page-banner {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@media screen and (min-width: 1200px) {
    .page-banner {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

/* contact us css */
#contact-us {
}
.contact-us .desc {
    /* line-height: 25px; */
}
@media screen and (min-width: 992px) {
    .contact-us .desc {
        max-width: 80%;
        margin: 0 auto;
    }
}
.contact-us .contact-item .icon {
    height: 80px;
    width: 80px;
    background-color: var(--primaryLightestColor);
    color: var(--primaryColor);
    transition: var(--defaultTransitionTiming);
}
.contact-us .contact-item:hover .icon {
    background-color: var(--secondaryColor);
    color: #fff;
}
.contact-us .text p {
    /* line-height: 30px; */
}

/* disclaimer sec css */
#disclaimer-sec {
}
.disclaimer-sec .desc {
    /* line-height: 30px; */
}

/* terms and privacy policies sec css */
#terms-and-privacy-policies-sec {
}
.terms-and-privacy-policies-sec .desc {
    /* line-height: 25px; */
}

/* FOOTER : TOP SEC */
#footer-top-sec {
}
.footer-top-sec {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media screen and (min-width: 992px) {
    .footer-top-sec {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.footer-top-sec .send-message-btn {
    right: 0;
    bottom: 100%;
    border-radius: var(--appDefaultBorderRadius) var(--appDefaultBorderRadius)
        0px 0px;
    transition: var(--defaultTransitionTiming);
}
.footer-top-sec .send-message-btn:hover {
    opacity: 0.8;
}
.footer-top-sec .send-message-btn:active {
    opacity: 1;
}
@media screen and (min-width: 768px) {
    .footer-top-sec .send-message-btn {
        width: 200px;
    }
}
@media screen and (min-width: 992px) {
    .footer-top-sec .send-message-btn {
        width: 300px;
    }
}

/* FOOTER : BOTTOM SEC */
#footer-bottom-sec {
}
.footer-bottom-sec {
    padding-top: 30px;
    padding-bottom: 30px;
}

@media screen and (min-width: 992px) {
    .footer-bottom-sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.footer-bottom-sec .logo-footer {
    width: 110px;
}

@media screen and (min-width: 992px) {
    .footer-bottom-sec .logo-footer {
        width: 200px;
    }
}

.footer-links > a {
    color: #bebebe;
    transition: var(--defaultTransitionTiming);
    position: relative;
    padding-left: 15px;
}

.footer-links > a::before {
    content: "";
    height: 1px;
    width: 8px;
    background-color: var(--secondaryColor);
    position: absolute;
    top: 9px;
    left: 0;
    transition: var(--defaultTransitionTiming);
}

.footer-links > a:hover {
    color: #fff;
}

@media screen and (min-width: 992px) {
    .footer-links > a:hover {
        padding-left: 20px;
    }
    .footer-links > a:hover::before {
        width: 13px;
    }
}


#footer-copyright {

}
.footer-copyright {

}
/* success stories sec1 css */
#success-stories-sec1 {}
.success-stories-sec1 {
    
}
.success-stories-sec1 .rt-sec .exp {
    top: 20px;
    left: -15px;
    background: rgba(var(--secondaryColorRgbValue), 0.9);
    padding: 6px 15px;
}

/* success stories sec2 css */
#success-stories-sec2 {}
.success-stories-sec2 {
    
}

.success-stories-sec2 .st-heading-wrapper .desc {
    /* line-height: 25px; */
}
@media screen and (min-width: 992px) {
    .success-stories-sec2 .st-heading-wrapper .desc {
        max-width: 80%;
        margin: 0 auto;
    }
}

.success-stories-sec2 .ss2-item .img-sec {
    width: 100%;
}
@media screen and (min-width: 768px) {
    .success-stories-sec2 .ss2-item .img-sec {
        width: 270px;
    }
}
.success-stories-sec2 .ss2-item .bottom-sec .desc {
    /* line-height: 22px; */

}
.success-stories-sec2 .ss2-item .bottom-sec .desc:not(.expanded) {
    /* max-height: 70px;
    overflow: hidden; */
}
/* authentication style css */
#auth-container {
}

.auth-container .auth-main {
}
.auth-container .auth-main > .rt-sec > .inner {
    padding: 15px;
}

@media screen and (min-width: 768px) {
    .auth-container .auth-main > .rt-sec > .inner {
        padding: 20px 35px;
    }
}

/* program search */
#programs-search {
}
.programs-search {
}

@media screen and (min-width: 992px) {
    .programs-search .st-form {
        width: 300px;
    }
}

/* PROGRAM WEIGHT LOSS CHALLENGE */
#program-weight-loss-challenge {
}
.program-weight-loss-challenge {
    
}
.program-weight-loss-challenge .nd-sec .rt-sec ol {
    list-style: decimal;
    padding-left: 20px;
}
.program-weight-loss-challenge .nd-sec .rt-sec ol > li {
    /* color: gray !important; */
    color: var(--textLightColor);
    margin-bottom: .5rem!important;
}

.program-weight-loss-challenge .st-heading-wrapper .desc {
    /* line-height: 25px; */
}

/* PROGRAM PLANS */
#program-plans {
}
.program-plans .plan-item {
    padding-left: 15px;
    padding-right: 15px;
}
@media screen and (min-width: 1200px) {
    .program-plans .plan-item {
        padding-left: 35px;
        padding-right: 35px;
    }
}
.program-plans .plan-item::after {
    content: "";
    position: absolute;
    top: -5px;
    left: 15px;
    height: 100%;
    width: calc(100% - (15px * 2));
    border-radius: 10px;
}
.program-plans .plan-item:nth-child(even)::after {
    background-color: var(--primaryColor);
}
.program-plans .plan-item:nth-child(odd)::after {
    background-color: var(--secondaryColor);
}
@media screen and (min-width: 1200px) {
    .program-plans .plan-item::after {
        left: 35px;
        height: 100%;
        width: calc(100% - (35px * 2));
    }
}

.program-plans .plan-item > .inner {
    border-radius: 10px;
    z-index: 1;
    transition: var(--defaultTransitionTiming);
}

.program-plans .plan-item:nth-child(even) > .inner:hover {
    box-shadow: 0px -2px 15px 0 rgba(var(--primaryColorRgbValue), 0.2);
}
.program-plans .plan-item:nth-child(odd) > .inner:hover {
    box-shadow: 0px -2px 15px 0 rgba(var(--secondaryColorRgbValue), 0.2);
}

/* RECIPES SEARCH */
#recipes-search {
}
.recipes-search {
}

.recipes-search-main {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
}
@media screen and (min-width: 768px) {
    .recipes-search-main {
        border-radius: 40px;
        padding: 0;
    }
}

.recipes-search-main input,
.recipes-search-main select {
    border: none !important;
    background-color: transparent !important;
}
@media screen and (min-width: 768px) {
    .recipes-search-main .frac.select .st-form::after,
    .recipes-search-main .frac.switch .st-form::after {
        content: "";
        width: 1px;
        height: 25px;
        background-color: var(--colorGray1);
        position: absolute;
        right: 0;
        top: 10px;
    }
}

.recipes-search-main .frac.switch > .inner {
    height: 42px;
}

.recipes-search-main {
    width: 100%;
}

@media screen and (min-width: 768px) {
    /* .recipes-search-main .frac.select {
        width: 170px;
    }
    .recipes-search-main .frac.switch {
        width: 100px;
    }
    .recipes-search-main .frac.input {
        width: 200px;
    } */
}
@media screen and (min-width: 992px) {
    .recipes-search-main {
        width: 400px;
    }
    /* .recipes-search-main .frac.select {
        width: 250px;
    }
    .recipes-search-main .frac.switch {
        width: 130px;
    }
    .recipes-search-main .frac.input {
        width: 250px;
    } */
}

/* @media screen and (min-width: 768px) {
    .recipes-search-main .btns .st-btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
@media screen and (min-width: 992px) {
    .recipes-search-main .btns .st-btn {
        min-width: 150px;
    }
} */

/* RECIPES LIST */
#recipes-list {
}
.recipes-list {
}
.recipes-list .recipe-item .img-sec {
    /* height: 160px; */
    background-color: var(--lightBgColor);
}
@media screen and (max-width: 575px) {
    .recipes-list .recipe-item .img-sec img {
        width: 100%;
    }
}
@media screen and (min-width: 576px) {
    .recipes-list .recipe-item .img-sec {
        height: 160px;
    }
}

.recipes-list .recipe-item > .inner {
    border-radius: 20px;
    color: var(--textDarkColor);
    transition: var(--defaultTransitionTiming);
}
.recipes-list .recipe-item > .inner:hover {
    border-radius: 20px;
    color: var(--primaryColor);
}

/* RECIPE DETAILS SECTION */
#recipe-details {
}
.recipe-details {
}

.recipe-details-img {
}
.recipe-details-img .caption {
    bottom: 15px;
}

/* RECIPE-DETAILS */
#recipe-details {
}
.recipe-details .img-sec img {
    width: 100%;
}
.recipe-details .img-sec .date {
    left: 20px;
    bottom: -8px;
}

/* RECIPES CATS */
.recipes-cats {
}
.recipes-cats .list-container {
}
.recipes-cats .list-container .link {
    padding: 9px 15px 7px;
    display: block;
    border: 1px solid rgb(235, 235, 235);
    font-size: 14px;
    border-radius: 5px;
    color: #333;
    transition: 0.2s;
    background-color: #fff;
}
.recipes-cats .list-container .link:hover {
    background-color: var(--primaryColor);
    color: #fff;
}

/* MY ACCOUNT CSS */
.account-menu {
}

.my-account {
  min-height: 500px;
}

@media screen and (min-width: 768px) {
  .ma-leftbar {
    width: 285px;
  }

  .ma-content {
    margin-left: 285px;
    width: calc(100% - 285px);
  }
}

.ma-leftbar-user-details {
  box-shadow: 0 1px 2px #c3c3c3;
}

/* menu */
.ma-leftbar-menu {
}
.ma-leftbar-menu .menu-item {
}
.ma-leftbar-menu .menu-link {
  color: var(--textLightColor);
  transition: var(--defaultTransitionTiming);
}
.ma-leftbar-menu .menu-item.active > .menu-link,
.ma-leftbar-menu .menu-link.active,
.ma-leftbar-menu .menu-link:hover {
  background-color: var(--primaryColor);
  color: #fff;
}
.ma-leftbar-menu .dropdown-menu-item .menu-link {
  background: #ececec;
}
.ma-leftbar-menu .dropdown-menu-item.active > .menu-link,
.ma-leftbar-menu .dropdown-menu-item > .menu-link.active,
.ma-leftbar-menu .dropdown-menu-item > .menu-link:hover {
  background: #c3c3c3;
  color: var(--textLightColor);
}

/* MA DASHBOARD CONTENT */
.ma-content.dashboard-content {
}
.ma-content.dashboard-content .frac > .inner {
  transition: var(--defaultTransitionTiming);
}
.ma-content.dashboard-content .frac:hover > .inner {
  border-color: #bebebe !important;
}

/* MA LOGIN INFORMATION CONTENT */
.ma-content.login-information-content {
}

/* diet plan */
.diet-plan-container {
}
.diet-plan-container ul {
  list-style: disc;
  padding-left: 20px;
}

/* aarogyam list container */
.aarogyam-list-container {
  margin: 0 -10px;
}
.aarogyam-list-container .pdf-item {
  padding: 0 10px 20px;
  min-width: 150px;
}
.aarogyam-list-container .pdf-item > .inner {
  background: #efefef;
  padding: 15px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  /* cursor: pointer; */
  /* transition: 0.2s; */
}
/* .aarogyam-list-container .pdf-item > .inner:hover {
  background: #dfdfdf;
} */

.aarogyam-list-container .pdf-item .icon {
  margin-bottom: 10px;
}
.aarogyam-list-container .pdf-item .text {
  line-height: normal;
}

.aarogyam-list-container .pdf-item .text p {
  font-size: 15px;
  font-weight: 600;
}
.aarogyam-list-container .pdf-item .text a {
  font-size: 13px;
  font-weight: 400;
}

/* disclaimer sec css */
#disclaimer-sec {
}
.disclaimer-sec .desc {
    /* line-height: 30px; */
}

.active-tab {
    background: #149159;
    color: #fff;
}
/* success stories sec1 css */
#st-full-page {
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
  background-position: 0 0;
}
.st-full-page {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 992px) {
  .st-full-page {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

.st-full-page .heading-wrapper .head {
  font-size: 45px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 10px;
  text-shadow: 2px 3px #6c6c6c;
}
.st-full-page .heading-wrapper .desc {
  font-weight: 800;
}
@media screen and (min-width: 768px) {
  .st-full-page .heading-wrapper .head {
    font-size: 70px;
  }
  .st-full-page .heading-wrapper .desc {
    font-size: 25px;
  }
}
@media screen and (min-width: 992px) {
  .st-full-page .heading-wrapper .head {
    font-size: 100px;
  }
}
.st-full-page .cotent-wrapper {
  margin-top: 40px;
}
@media screen and (min-width: 992px) {
  .st-full-page .cotent-wrapper {
    margin-top: 100px;
  }
}
.st-full-page .cotent-wrapper .sec-item > .inner {
  padding: 50px 20px;
  height: 100%;
}
.st-full-page .cotent-wrapper .sec-item .img-sec {
  height: 100px;
  width: 100px;
  padding: 20px;
}
.st-full-page .cotent-wrapper .sec-item .text-sec {
  margin-top: 20px;
}
.st-full-page .cotent-wrapper .sec-item .text-sec .head {
  font-size: 25px;
  font-weight: 800;
}
.st-full-page .cotent-wrapper .sec-item .text-sec .desc {
  margin-top: 10px;
}
@media screen and (min-width: 992px) {
  .st-full-page .cotent-wrapper .sec-item .img-sec {
    height: 130px;
    width: 130px;
    padding: 5px;
  }
  .st-full-page .cotent-wrapper .sec-item .text-sec {
    margin-top: 40px;
  }
  .st-full-page .cotent-wrapper .sec-item .text-sec .head {
    font-size: 30px;
  }
  .st-full-page .cotent-wrapper .sec-item .text-sec .desc {
    font-size: 17px;
  }
}

@media screen and (max-width: 575px) {
  .st-full-page .cotent-wrapper .sec-item:nth-child(even) > .inner {
    background-color: #dedc10;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(odd) > .inner {
    background-color: #149159;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(even) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(even) .text-sec .desc {
    color: #333;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(odd) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(odd) .text-sec .desc {
    color: #fff;
  }
}
@media screen and (min-width: 768px) {
  .st-full-page .cotent-wrapper .sec-item:nth-child(even) > .inner {
    background-color: #dedc10;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(odd) > .inner {
    background-color: #149159;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(even) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(even) .text-sec .desc {
    color: #333;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(odd) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(odd) .text-sec .desc {
    color: #fff;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .st-full-page .cotent-wrapper .sec-item > .inner {
    background-color: #149159;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(2) > .inner,
  .st-full-page .cotent-wrapper .sec-item:nth-child(3) > .inner,
  .st-full-page .cotent-wrapper .sec-item:nth-child(6) > .inner {
    background-color: #dedc10;
  }
  .st-full-page .cotent-wrapper .sec-item .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item .text-sec .desc {
    color: #fff;
  }
  .st-full-page .cotent-wrapper .sec-item:nth-child(2) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(3) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(6) .text-sec .head,
  .st-full-page .cotent-wrapper .sec-item:nth-child(2) .text-sec .desc,
  .st-full-page .cotent-wrapper .sec-item:nth-child(3) .text-sec .desc,
  .st-full-page .cotent-wrapper .sec-item:nth-child(6) .text-sec .desc {
    color: #333;
  }
}

header {
    top: 0;
    background-color: #fff;
    z-index: 12;
}
/* HEADER TOP SEC */
#header-top-sec {
}
.header-top-sec {
    padding-top: 6px;
    padding-bottom: 6px;
}
.header-top-sec .ht-link-login-and-register {
}
.header-top-sec .ht-link-login-and-register > a:not(:last-child) {
    margin-right: 5px;
}
.header-top-sec .ht-link-login-and-register > a:not(:last-child)::after {
    content: "|";
    margin-left: 5px;
}

/* HEADER MAIN SEC */
#header-main-sec {
}
.header-main-sec {
    padding-top: 6px;
    padding-bottom: 6px;
}
.header-main-sec .navbar-nav {
}
#header-nav-navbar .nav-link {
    color: var(--textLightColor);
    transition: var(--defaultTransitionTiming);
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 5px;
    
}
@media screen and (min-width: 768px) {
    #header-nav-navbar .nav-link {
        border-bottom: 2px solid transparent;
    }
    #header-nav-navbar .nav-link,
    #header-nav-navbar .dropdown-toggle.nav-link {
        margin-right: 15px;
    }
}
@media screen and (min-width: 1200px) {
    #header-nav-navbar .nav-link,
    #header-nav-navbar .dropdown-toggle.nav-link {
        margin-right: 24px;
    }
}
#header-nav-navbar .nav-link:hover,
#header-nav-navbar .nav-link:active,
#header-nav-navbar .nav-link.active,
#header-nav-navbar .nav-item.show > .dropdown-toggle.nav-link {
    color: var(--secondaryColor);
    border-bottom-color: var(--secondaryColor);
}

#header-nav-navbar .dropdown-menu {
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    top: calc(100% + 5px);
    padding: 0;
}
#header-nav-navbar .dropdown-item {
    padding: 8px 15px 9px;
    color: var(--textLightColor);
    transition: var(--defaultTransitionTiming);
}

#header-nav-navbar .dropdown-item:hover {
    background-color: var(--primaryColor);
    color: #fff;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: transparent;
}
