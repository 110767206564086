/* BORDER CLASSES */
.st-border-gray {
    border-color: var(--borderGrayColor) !important;
}
.st-border-outer-space {
    border-color: var(--borderOuterSpaceColor) !important;
}
.st-border-primary {
    border-color: var(--primaryColor) !important;
}
.st-border-secondary {
    border-color: var(--secondaryColor) !important;
}
.st-border-default {
    border-color: var(--borderDefaultColor) !important;
}

/* SOCIAL LINKS ICONS */
.social-links-icons {
}
.social-links-icons > li {
}
.social-links-icons > li:not(:last-child) {
    margin-right: 5px;
}
.social-links-icons > li > a {
    height: 30px;
    width: 30px;
}

/* COMMON MARGINS AND PADDING */
.ST_def-mar-T,
.ST_def-mar-TB {
    margin-top: 20px;
}
@media screen and (min-width: 992px) {
    .ST_def-mar-T,
    .ST_def-mar-TB {
        margin-top: 35px;
    }
}
@media screen and (min-width: 1200px) {
    .ST_def-mar-T,
    .ST_def-mar-TB {
        margin-top: 80px;
    }
}

.ST_def-mar-B,
.ST_def-mar-TB {
    margin-bottom: 20px;
}
@media screen and (min-width: 992px) {
    .ST_def-mar-B,
    .ST_def-mar-TB {
        margin-bottom: 35px;
    }
}
@media screen and (min-width: 1200px) {
    .ST_def-mar-B,
    .ST_def-mar-TB {
        margin-bottom: 80px;
    }
}

.ST_def-pad-T,
.ST_def-pad-TB {
    padding-top: 20px;
}
@media screen and (min-width: 992px) {
    .ST_def-pad-T,
    .ST_def-pad-TB {
        padding-top: 35px;
    }
}
@media screen and (min-width: 1200px) {
    .ST_def-pad-T,
    .ST_def-pad-TB {
        padding-top: 80px;
    }
}

.ST_def-pad-B,
.ST_def-pad-TB {
    padding-bottom: 20px;
}
@media screen and (min-width: 992px) {
    .ST_def-pad-B,
    .ST_def-pad-TB {
        padding-bottom: 35px;
    }
}
@media screen and (min-width: 1200px) {
    .ST_def-pad-B,
    .ST_def-pad-TB {
        padding-bottom: 80px;
    }
}

/* IMAGE CLASSES */
.img-fluid-height {
    max-height: 100%;
}

/* UNDERLINE IT */
.underline-it {
}
.underline-it::after {
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    margin-top: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--textLightColor);
}
.underline-it.underline-secondary::after {
    background-color: var(--secondaryColor);
}
.underline-it.underline-primary::after {
    background-color: var(--primaryColor);
}

/* ST BREADCRUMBS */
.st-breadcrumbs {
}
.st-breadcrumbs > li:not(:last-child)::after {
    content: ">";
    margin: 0 5px;
    font-weight: 600;
    color: var(--textDarkColor);
}

.st-breadcrumbs > li > a:hover {
    color: var(--primaryColor) !important;
}

/* ST PROGRESS */
.st-progress {
    height: 14px;
    border-radius: 20px;
}
.st-progress.progress-success {
    background-color: var(--colorGray1);
}
.st-progress.progress-success > .progress-bar {
    background-color: var(--colorSuccess);
}
.st-progress.progress-primary > .progress-bar {
    background-color: var(--primaryColor);
}
.st-progress.progress-secondary > .progress-bar {
    background-color: var(--secondaryColor);
}

/* ST-BADGE */
.st-badge {
    height: 20px;
    width: 20px;
    background-color: #333;
    color: #fff;
}
.st-badge.st-badge-secondary {
    background-color: var(--secondaryColor);
}

/* BOX SHADOW */
.st-block-box-shadow {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

/* ST SWITCH */
.st-switch {
    cursor: pointer;
  }
  .st-switch .bar {
    width: 32px;
    height: 18px;
    background: #ddd;
    border-radius: 20px;
    position: relative;
    transition: 0.3s;
}
.st-switch .bar .round {
    height: 18px;
    width: 18px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}
.st-switch > input:checked ~ .bar {
    background: var(--colorSuccess);
}
.st-switch > input:checked ~ .bar .round {
    left: 15px;
}

/* ST-TAG */
.st-tag1 {
    background: transparent url("../../images/icons/tag-bg1.png") no-repeat left
        center / auto 100%;
    padding-left: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 31px;
}

/* st-img-radio */
.st-img-radio {
    cursor: pointer;
}
.st-img-radio .box {
    transition: var(--defaultTransitionTiming);
    border-color: transparent !important;
    border-radius: var(--appDefaultBorderRadius);
    height: 150px;
}
.st-img-radio > input:checked + .box {
    border-color: var(--primaryColor) !important;
}
.st-img-radio .text {
}
.st-img-radio .text.active {
    color: var(--primaryColor);
}

/* ST-CHECKBOX */
.st-checkbox {
    cursor: pointer;
}
.st-checkbox .box {
    height: 20px;
    width: 20px;
    background-color: #ddd;
    color: #ddd;
    transition: var(--defaultTransitionTiming);
}

.st-checkbox .text {
    top: -2px;
}

.st-checkbox > input:checked + .box {
    background-color: var(--primaryColor) !important;
    color: #fff;
}

/* MODAL BOOTSTRAP STYLES */
.modal-content {
    border-radius: 0;
}

/* ALERT */
.alert {
    padding: 5px 15px 5px;
    border-radius: 0;
    font-size: 14px;
}
