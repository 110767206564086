/* st line heading */
.st-line-heading {
    padding-left: calc(50px + 5px);
}
.st-line-heading::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translatey(-50%);
    width: 50px;
    height: 2px;
    background-color: var(--textLightColor);
}
/* line secondary */
.st-line-heading.line-secondary::after {
    background-color: var(--secondaryColor);
}
/* line primary */
.st-line-heading.line-primary::after {
    background-color: var(--primaryColor);
}

/* st heading */
.st-heading {
    font-size: 31px;
}
@media screen and (min-width: 768px) {
    .st-heading {
        font-size: 40px;
    }
}
@media screen and (min-width: 992px) {
    .st-heading {
        font-size: 50px;
    }
}

/* heading XS */
.st-heading.heading-xs {
    font-size: 24px;
}

/* heading SM */
.st-heading.heading-xs2 {
    font-size: 34px;
    line-height: 42px;
}

/* heading SM */
.st-heading.heading-sm {
    font-size: 31px;
    line-height: 35px;
}

@media screen and (min-width: 992px) {
    .st-heading.heading-sm {
        font-size: 40px;
        line-height: 40px;
    }
}

/* heading underline */
.st-heading.heading-underline {
    padding-bottom: 5px;
    border-bottom: 2px solid var(--borderGrayColor);
}
.st-heading.heading-underline::after {
    content: '';
    height: 4px;
    width: 70px;
    position: absolute;
    bottom: -3px;
    left: 0;
    background-color: var(--secondaryColor);
}