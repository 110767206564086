/* authentication style css */
#auth-container {
}

.auth-container .auth-main {
}
.auth-container .auth-main > .rt-sec > .inner {
    padding: 15px;
}

@media screen and (min-width: 768px) {
    .auth-container .auth-main > .rt-sec > .inner {
        padding: 20px 35px;
    }
}
